import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The environmental lab is responsible for the sampling, recovery, and analysis of organic material, consisting of the remains of plants and animals, from Gabii’s past. Animal bones are collected by hand in the field. Most parts of plants, like flowers, leaves, and roots decay over time; only seeds and woods are reliably preserved after turning to carbon through exposure to fire in the past. In a process called flotation, soil is added to water, and charred plant remains rise to the top where they can be collected. Experts then identify and quantify all the recovered botanical and zoological specimens. We seek to answer questions about the foodways of Gabii’s inhabitants using these materials. What were the main staples of diet in Gabii’s earliest phases, and how did they change over time as the settlement grew? Did the city produce most of its own food, or was it imported from the surrounding countryside, or even farther away? When did Gabii develop a centralized system of food distribution? Early results show distinct preferences for staple grains in different hut groups in the early phases of the city, as well as a lot of millet, a food found more commonly near Pompeii. Other avenues of research include the use of plants and animals in ritual or religious contexts, micromorphological analyses of soil layers to help assess things like architectural techniques, pasturing habits, and storage processes.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/13717434_10154347647599025_2483602134652679370_o.jpg","alt":null,"title":"Gabii staff members analyzing and recording bioarchaeological ecofacts"}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Environmental Studies","key":"environmental-studies","parentKey":"the-environmant-of-gabii"};

  